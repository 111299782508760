import React from 'react';

import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from '../components/layout/main';
import Head from '../components/layout/head';

const AboutGanen = () => {
  const { map } = useStaticQuery(graphql`
    query {
      map: file(relativePath: { eq: "world-map.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Head title={'About GANEN'} />

      <div className="box-container px-3">
        <div className="mw-900 mx-auto">
          <h2 className="fs-42 ganen-green-bold my-3 my-md-5 text-center">
            About GANEN
          </h2>

          <div className="text-center">
            <p className="mb-5">
              GANEN is a distributor of innovative home automation products.
              <br className="d-none d-sm-block" />
              Our products are designed and specially curated to provide your
              homes and
              <br className="d-none d-sm-block" />
              your families with a perfect level of efficiency, comfort and
              peace of mind.
            </p>

            <p className="mb-5">
              We specialise in smart home and internet-enabled appliances that
              bring you
              <br className="d-none d-sm-block" />
              the comfort and efficiency that you deserve.
              <br className="d-none d-sm-block" />
              Reliability and innovation go together which is why our products
              are time-tested.
              <br className="d-none d-sm-block" />
              With our products, you can make your home a true safe haven.
            </p>

            <p className="mb-5">
              We believe that when you spend less time worrying about mundane
              things about your
              <br className="d-none d-sm-block" />
              home such as construction and electricity bills, you spend more
              time with
              <br className="d-none d-sm-block" />
              your loved ones in your elegant and personally transformed home.
            </p>

            <p className="mb-5">
              We hope that our products bring you joy from the moment you say
              “I’m home.”
            </p>

            <div className="mb-5">
              <BackgroundImage
                fluid={map.childImageSharp.fluid}
                className="mx-auto world-map"
              ></BackgroundImage>
            </div>

            <div className="mb-5">
              <h3 className="fs-35 ganen-green-bold mb-4">Mission</h3>
              <p>
                To continuously reinvent automation as a service that creates
                functions extremely meaningful to our users.
              </p>
            </div>

            <div className="mb-5">
              <h3 className="fs-35 ganen-green-bold mb-4">Vision</h3>
              <p>
                To be the trusted name in automation for every space. The Idea -
                To provide worry-free home automation solutions for every
                household throughout SEA and South Asia, and to be leaders in
                innovation of internet-enabled products that are highly
                desirable in both form and function.
              </p>
            </div>

            <div className="mb-5">
              <h3 className="fs-35 ganen-green-bold mb-4">Values</h3>
              <p>
                Heart our people | Service Excellence | Safety | Dependable |
                Prudence | Integrity | Environmentally Conscious
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AboutGanen;
